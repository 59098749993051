<template>
  <v-dialog v-model="isShow" min-width="300" max-width="400" @click:outside="onClickOutside">
    <v-card>
      <v-toolbar :title="title" :color="isDelete ? 'red' : 'primary'" />
      <v-card-text class="pa-0 pb-2">
        <slot></slot>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn variant="outlined" size="large" @click="onClose()">
          {{ closeButtonText }}
        </v-btn>
        <v-btn
          v-if="showPrimaryButton"
          color="primary"
          variant="flat"
          size="large"
          :disabled="disabled"
          @click="onClick()"
        >
          {{ primaryButtonText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DialogTemplate',
  props: {
    show: {
      type: Boolean,
      required: false,
      default: false
    },
    title: {
      type: String,
      required: true
    },
    enableOutside: {
      type: Boolean,
      required: false,
      default: true
    },
    // 削除モーダルを表示する際に使用
    isDelete: {
      type: Boolean,
      required: false,
      default: false
    },
    closeButtonText: {
      type: String,
      required: false,
      default: '閉じる'
    },
    primaryButtonText: {
      type: String,
      required: false,
      default: ''
    },
    showPrimaryButton: {
      type: Boolean,
      required: false,
      default: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    isShow() {
      return this.show
    }
  },
  methods: {
    onClose() {
      this.$emit('close', false)
    },
    onClick() {
      this.$emit('action', false)
    },
    onClickOutside() {
      this.enableOutside && this.$emit('close', false)
    }
  }
}
</script>

<style lang="scss"></style>
