const Snackbar = {
  namespaced: true,
  state: {
    // Snackbar
    showSnackbar: false,
    snackbarText: '',
    snackbarTime: 3000
  },
  mutations: {
    setShowSnackbar: (state, bool) => (state.showSnackbar = bool),
    setSnackbarText: (state, text) => (state.snackbarText = text),
    setSnackbarTime: (state, time) => (state.snackbarTime = time)
  }
}

export default Snackbar
