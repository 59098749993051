<template>
  <v-container>
    <v-layout align-content-space-around class="mb-4">
      <div class="text-h5">テナント一覧</div>
      <v-spacer />
      <v-btn class="mb-4" color="primary" @click="clearCreateItem(), (showCreateDialog = true)">
        テナント登録
      </v-btn>
    </v-layout>

    <v-card>
      <v-table class="table-wrapper">
        <thead>
          <tr>
            <th>テナント名</th>
            <th>テナント情報</th>
            <th>登録日時</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody v-if="isLoading">
          <tr>
            <td colspan="4">
              <v-row justify="center" class="pa-12">
                <v-progress-circular v-if="isLoading" indeterminate :size="32" />
              </v-row>
            </td>
          </tr>
        </tbody>
        <tbody v-if="tenantList?.length == 0 && !isLoading">
          <tr>
            <td colspan="4" class="pa-12 text-center">ユーザーが見つかりません。</td>
          </tr>
        </tbody>
        <tbody>
          <tr v-for="(tenant, index) in sliceTenants()" :key="index">
            <td style="max-width: 360px; min-width: 180px" class="pt-2 pb-2">
              {{ tenant.tenantName }}
            </td>
            <td
              class="pt-2 pb-2 text-wrap"
              style="max-width: 360px; min-width: 180px; overflow-wrap: anywhere"
            >
              {{ tenant.information }}
            </td>
            <td width="157" style="min-width: 157px">{{ dateFormat(tenant.createdAt) }}</td>
            <td width="66" class="pr-2">
              <v-btn
                color="primary"
                variant="outlined"
                @click=";(showEditDialog = true), (selectedItem = { ...tenant })"
              >
                表示
              </v-btn>
            </td>
            <td class="pl-0" width="155">
              <v-btn
                color="primary"
                depressed
                @click="$router.push({ name: 'projects', params: { id: tenant.tenantId } })"
              >
                プロジェクト一覧
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-table>
    </v-card>
    <div class="text-center mt-8">
      <v-pagination
        v-model="page"
        :length="Math.ceil(this.tenantList.length / 10)"
        :total-visible="4"
        @update:modelValue="changePage"
      ></v-pagination>
    </div>
    <DialogTemplate
      :show="showCreateDialog"
      title="テナント登録"
      closeButtonText="キャンセル"
      primaryButtonText="登録"
      :disabled="checkValidation(createItem.tenantName)"
      @close="showCreateDialog = false"
      @action="createTenant"
    >
      <v-form>
        <v-card-text>
          <v-text-field
            v-model="createItem.tenantName"
            outlined
            label="テナント名"
            :rules="tenantNameRule"
            class="mb-2"
          />
          <v-textarea v-model="createItem.information" outlined label="テナント登録情報" />
          <div class="text-subtitle-2 mb-2">使用するクリップのフォーマット</div>
          <v-btn-toggle v-model="createItem.clipFormats" multiple>
            <v-btn disabled tile outlined value="hls">
              <v-icon size="20.5">
                {{
                  createItem.clipFormats.includes('hls')
                    ? 'mdi-checkbox-marked-circle'
                    : 'mdi-checkbox-blank-circle-outline'
                }}
              </v-icon>
              HLS
            </v-btn>
            <v-btn tile outlined value="mp4">
              <v-icon size="20.5">
                {{
                  createItem.clipFormats.includes('mp4')
                    ? 'mdi-checkbox-marked-circle'
                    : 'mdi-checkbox-blank-circle-outline'
                }}
              </v-icon>
              MP4
            </v-btn>
          </v-btn-toggle>
        </v-card-text>
      </v-form>
    </DialogTemplate>
    <DialogTemplate
      v-if="selectedItem"
      :show="showEditDialog"
      title="テナント編集"
      closeButtonText="キャンセル"
      primaryButtonText="更新"
      :disabled="checkValidation(selectedItem.tenantName)"
      @close="showEditDialog = false"
      @action="editTenant"
    >
      <v-form class="pa-8 pt-6 pb-4">
        <copiable-text-field
          :value="selectedItem.tenantId"
          outlined
          label="テナントID"
          disabled="true"
        />
        <v-text-field
          v-model="selectedItem.tenantName"
          outlined
          label="テナント名"
          :rules="tenantNameRule"
          class="mb-2"
        />
        <v-textarea v-model="selectedItem.information" outlined label="テナント登録情報" />
        <div class="text-subtitle-2 mb-2">使用するクリップのフォーマット</div>
        <v-btn-toggle v-model="selectedItem.clipFormats" multiple>
          <v-btn disabled tile outlined value="hls">
            <v-icon size="20.5">
              {{
                selectedItem.clipFormats.includes('hls')
                  ? 'mdi-checkbox-marked-circle'
                  : 'mdi-checkbox-blank-circle-outline'
              }}
            </v-icon>
            HLS
          </v-btn>
          <v-btn tile outlined value="mp4">
            <v-icon size="20.5">
              {{
                selectedItem.clipFormats.includes('mp4')
                  ? 'mdi-checkbox-marked-circle'
                  : 'mdi-checkbox-blank-circle-outline'
              }}
            </v-icon>
            MP4
          </v-btn>
        </v-btn-toggle>
      </v-form>
    </DialogTemplate>

    <!-- ErrorDialog -->
    <ErrorDialog
      :error="hasError"
      :title="errorTitle"
      :text="errorMessage"
      @close="hasError = false"
    />
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { systemTenants } from '@/constants/system-tenants'
import dayjs from '@/common/dayjs'
import CopiableTextField from '@/components/common/CopiableTextFielde'
import DialogTemplate from '@/components/common/DialogTemplate'
import ErrorDialog from '@/components/common/ErrorDialog'

const { mapState: mapStateTenant, mapActions: mapActionsTenant } = createNamespacedHelpers('Tenant')
const { mapMutations: mapMutationsSnackbar } = createNamespacedHelpers('Snackbar')

export default {
  name: 'TenantList',
  components: { CopiableTextField, DialogTemplate, ErrorDialog },
  data() {
    return {
      isLoading: false,
      showCreateDialog: false,
      showEditDialog: false,
      selectedItem: null,
      projectId: null,
      tenantList: [],
      createItem: {
        tenantName: '',
        information: '',
        clipFormats: ['hls']
      },
      page: 1,
      tenantNameRule: [v => !!v || 'テナント名を入力してください'],
      hasError: false,
      errorTitle: '',
      errorMessage: ''
    }
  },
  computed: {
    ...mapStateTenant(['tenants']),
    user() {
      return this.$store.state.user
    }
  },
  async mounted() {
    this.page = this.$route.query.page ? parseInt(this.$route.query.page) : 1
    await this.getTenantList()
  },
  methods: {
    ...mapMutationsSnackbar(['setShowSnackbar', 'setSnackbarText']),
    ...mapActionsTenant(['getTenants', 'postTenants', 'putTenants']),
    dateFormat(date) {
      return dayjs(date).format('YYYY年M月D日')
    },
    async getTenantList() {
      this.isLoading = true
      try {
        await this.getTenants(this.tenantId, this.projectId)
        systemTenants

        this.tenantList = this.tenants.filter(tenant => !systemTenants.includes(tenant.tenantName))
      } catch (error) {
        this.hasError = true
        this.errorTitle = 'テナントの取得に失敗しました'
        this.errorMessage = 'テナントの取得に失敗しました。再度お試しください。'
      } finally {
        this.isLoading = false
      }
    },
    async createTenant() {
      try {
        await this.postTenants(this.createItem)

        this.showCreateDialog = false
        await this.getTenantList()

        this.setShowSnackbar(true)
        this.setSnackbarText('テナントを追加しました。')
      } catch (error) {
        this.hasError = true
        this.errorTitle = '登録に失敗しました'
        this.errorMessage = '入力内容をご確認の上、再度お試しください。'
      }
    },
    async editTenant() {
      try {
        await this.putTenants(this.selectedItem)

        this.showEditDialog = false
        await this.getTenantList()
        this.setShowSnackbar(true)
        this.setSnackbarText('テナントを更新しました。')
      } catch (error) {
        this.hasError = true
        this.errorTitle = '更新に失敗しました'
        this.errorMessage = '入力内容をご確認の上、再度お試しください。'
      }
    },
    changePage(page) {
      this.page = page
      this.$router.push(`/tenants/?page=${page}`)
    },
    sliceTenants() {
      return this.tenantList.slice((this.page - 1) * 10, this.page * 10)
    },
    clearCreateItem() {
      this.createItem = {
        tenantName: '',
        information: '',
        clipFormats: ['hls']
      }
    },
    checkValidation(value) {
      return value === ''
    }
  }
}
</script>

<style lang="scss"></style>
