import axios from 'axios'
import Store from '@/store'

const instance = axios.create({
  baseURL: import.meta.env.VITE_APP_API_URL_BASE,
  timeout: 3000,
  responseType: 'json',
  validateStatus: status => (status >= 200 && status < 300) || status == 404,
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': import.meta.env.VITE_APP_X_API_KEY
  }
})
// リクエスト設定(+ログ出力)
instance.interceptors.request.use(
  async config => {
    config.headers.Authorization = (
      await Store.dispatch('Auth/getUserAuthentication')
    ).signInUserSession.idToken.jwtToken

    return config
  },
  error => {
    console.error(error)
    return Promise.reject(error)
  }
)

// レスポンスログ
instance.interceptors.response.use(
  response => {
    console.info('API Response', response)
    return response
  },
  error => {
    console.error(error)
    return Promise.reject(error)
  }
)

export default instance
