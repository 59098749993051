<template>
  <v-dialog v-model="isError" min-width="300" max-width="400" @click:outside="onClose">
    <v-card>
      <v-toolbar :title="title" color="red" />
      <v-card-text class="pt-8 pb-4 ps-4">{{ text }}</v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="grey" variant="flat" size="large" @click="onClose()">
          {{ closeButtonText }}
        </v-btn>
        <v-btn
          v-if="showPrimaryButton"
          color="primary"
          variant="flat"
          size="large"
          @click="onClick()"
        >
          {{ primaryButtonText }}
        </v-btn>
        <v-btn
          v-if="showSecondaryButton"
          color="teal"
          variant="flat"
          size="large"
          @click="onClickSecondary"
        >
          {{ secondaryButtonText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ErrorDialog',
  props: {
    error: {
      type: Boolean,
      required: false,
      default: false
    },
    title: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    closeButtonText: {
      type: String,
      required: false,
      default: '閉じる'
    },
    primaryButtonText: {
      type: String,
      required: false,
      default: ''
    },
    secondaryButtonText: {
      type: String,
      required: false,
      default: ''
    },
    showPrimaryButton: {
      type: Boolean,
      required: false,
      default: false
    },
    showSecondaryButton: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    isError() {
      return this.error
    }
  },
  methods: {
    onClose() {
      this.$emit('close', false)
    },
    onClick() {
      this.$emit('clickPrimary', false)
    },
    onClickSecondary() {
      this.$emit('clickSecondary', false)
    }
  }
}
</script>

<style lang="scss"></style>
