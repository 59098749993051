import axios from '@/common/axios'

const Project = {
  namespaced: true,
  state: {
    newProjectId: null,
    projects: [],
  },
  mutations: {
    setProjects: (state, projects) => (state.projects = projects),
    setNewProjectId: (state, newProjectId) => (state.newProjectId = newProjectId),
  },
  getters: {},
  actions: {
    async getProjects({ commit, rootState }) {
      try {
        const { data } = await axios.get(`/tenants/${rootState.Tenant.tenantId}/projects`)
        commit('setProjects', data.projects)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async postProject({ commit, rootState }, { projectName }) {
      try {
        const { data } = await axios.post(`/tenants/${rootState.Tenant.tenantId}/projects`, {
          projectName: projectName,
        })
        commit('setNewProjectId', data.projectId)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async putProject({ rootState }, project) {
      try {
        return await axios.put(
          `/tenants/${rootState.Tenant.tenantId}/projects/${project.projectId}`,
          {
            ...project,
          }
        )
      } catch (error) {
        return Promise.reject(error)
      }
    },
  },
}

export default Project
