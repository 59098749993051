import axios from '@/common/axios'
import dayjs from '@/common/dayjs'

const Tenant = {
  namespaced: true,
  state: {
    tenantId: null,
    tenants: [],
  },
  mutations: {
    setTenantId: (state, tenantId) => (state.tenantId = tenantId),
    setTenants: (state, tenants) => (state.tenants = tenants),
  },
  getters: {
    getTenantId(state) {
      return state.tenantId
    },
  },
  actions: {
    async getTenants({ commit }, tenantId, projectId) {
      try {
        const { data } = await axios.get('/tenants', {
          params: {
            tenantId: tenantId,
            projectId: projectId,
          },
        })

        commit(
          'setTenants',
          data.tenants.sort((a, b) => {
            return dayjs(a.createdAt).format('YYYY-MM-DD HH:mm:ss') >
              dayjs(b.createdAt).format('YYYY-MM-DD HH:mm:ss')
              ? -1
              : 1
          })
        )
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async postTenants(_, tenant) {
      try {
        return await axios.post('tenants', { ...tenant })
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async putTenants(_, tenant) {
      try {
        return await axios.put(`tenants/${tenant.tenantId}`, { ...tenant })
      } catch (error) {
        return Promise.reject(error)
      }
    },
  },
}

export default Tenant
