<template>
  <v-container class="mt-10">
    <v-card elevation="2" shaped>
      <v-card-title>404 NotFound</v-card-title>
      <v-card-text>ページが見つかりません。</v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'Not Found',
}
</script>
