<template>
  <v-container>
    <v-card color="white" class="mt-12" max-width="940">
      <v-toolbar title="ログイン" color="indigo" />
      <v-row v-if="!isAuthorized" justify="center" class="pa-12">
        <v-col cols="auto">
          <v-btn color="primary" size="x-large" min-width="120" @click="signIn()">
            Googleアカウントでログイン
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
    <!-- ErrorDialog -->
    <ErrorDialog
      :error="hasError"
      :title="errorTitle"
      :text="errorMessage"
      @close="hasError = false"
    />
  </v-container>
</template>

<script>
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth'
import { createNamespacedHelpers } from 'vuex'
import ErrorDialog from '@/components/common/ErrorDialog'

const { mapState: mapStateAuth, mapActions: mapActionsAuth } = createNamespacedHelpers('Auth')
const { mapState: mapStateUser, mapActions: mapActionsUser } = createNamespacedHelpers('User')

export default {
  name: 'Login',
  components: {
    ErrorDialog
  },
  data() {
    return {
      hasError: false,
      errorTitle: '',
      errorMessage: ''
    }
  },
  computed: {
    ...mapStateAuth(['authUser', 'isAuthorized']),
    ...mapStateUser(['isUnregisteredUser'])
  },
  async created() {
    // NOTE：ログイン時に一回Googleの認証画面に遷移してしまってVuexにstateを追加出来ないので、読み込み時に実行
    await this.setUserAuthentication()

    if (this.isAuthorized) {
      try {
        const userId = this.authUser?.signInUserSession.accessToken.payload.sub
        // ユーザー情報を取得して登録されてなかった場合は登録処理を投げる
        await this.checkUnregisteredUser(userId)

        if (this.isUnregisteredUser) await this.postUsers()
      } catch (e) {
        this.hasError = true
        this.errorTitle = 'ログインに失敗しました'
        this.errorMessage = 'ログインに失敗しました。再度お試しください。'
      }

      this.$router.push({ name: 'tenants' })
    }
  },
  methods: {
    ...mapActionsAuth(['federatedSignIn', 'setUserAuthentication']),
    ...mapActionsUser(['checkUnregisteredUser', 'postUsers']),
    async signIn() {
      try {
        await this.federatedSignIn(CognitoHostedUIIdentityProvider.Google)
      } catch (error) {
        this.hasError = true
        this.errorTitle = 'ログインに失敗しました'
        this.errorMessage = 'ログインに失敗しました。再度お試しください。'
      }
    }
  }
}
</script>
