<template>
  <v-app-bar color="red">
    <img
      alt="PixyEye"
      class="ml-4 shrink hidden-sm-and-down logo"
      contain
      src="@/assets/pixyeye.svg"
      width="90"
    />

    <v-app-bar-title>
      <small>管理システム</small>
    </v-app-bar-title>

    <v-spacer />

    <account-menu v-if="authUser" class="pl-2 pr-1" />
  </v-app-bar>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'
import AccountMenu from '@/components/common/AccountMenu'

const { mapState: mapStateAuth } = createNamespacedHelpers('Auth')
export default {
  name: 'GlobalHeader',
  components: {
    AccountMenu
  },
  computed: {
    ...mapStateAuth(['authUser'])
  }
}
</script>
