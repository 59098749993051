import { createApp } from 'vue'
import App from './App.vue'
import { router } from './router'
import store from './store'
import URLParse from 'url-parse'
// Amplify読み込み
import { Amplify } from 'aws-amplify'
import config from './common/aws-exports'
import '@aws-amplify/ui-vue'
// GTM
import VueGtag from 'vue-gtag'

// Vuetify
import { registerPlugins } from '@/plugins'

const url = new URLParse(location.href)
config.oauth.redirectSignIn = config.oauth.redirectSignOut = `${url.protocol}//${url.host}/login`
config.oauth.responseType = 'code'
config.oauth.domain = import.meta.env.VITE_APP_OAUTH_DOMAIN
Amplify.configure(config)

const app = createApp(App)

registerPlugins(app)

app
  .use(VueGtag, {
    config: { id: import.meta.env.VITE_APP_GA4_ID }
  })
  .use(router)
  .use(store)
  .mount('#app')
