import { createRouter, createWebHistory } from 'vue-router'

import store from '../store/'

import TenantList from '../views/TenantList'
import ProjectList from '../views/ProjectList'
import Login from '../views/Login'
import NotFound from '../views/NotFound'

export const routes = [
  {
    // テナント一覧ページ
    path: '/tenants',
    name: 'tenants',
    component: TenantList,
    meta: {
      title: 'テナント一覧',
      requiresAuth: true
    },
    alias: '/'
  },
  {
    // プロジェクト一覧ページ
    path: '/tenants/:id/projects',
    name: 'projects',
    component: ProjectList,
    meta: {
      title: 'プロジェクト一覧',
      requiresAuth: true
    }
  },
  {
    // ログインページ
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      title: 'ログイン',
      requiresAuth: false
    }
  },
  {
    // 404ページ
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound
  }
]

export const router = createRouter({
  history: createWebHistory(),
  routes
})

// リダイレクト設定
router.beforeResolve(async (to, from, next) => {
  await store.dispatch('Auth/setUserAuthentication')

  if (to.meta.requiresAuth && !store.getters['Auth/getIsAuthorized']) {
    return next({
      path: '/login'
    })
  } else if (
    to.meta.disableIfAuthorized &&
    !to.meta.requiresAuth &&
    store.getters['Auth/getIsAuthorized']
  ) {
    return next({
      path: '/'
    })
  }
  return next()
})

// titleタグ指定
const DefaultTitle = 'PIXY EYE'
router.afterEach(to => {
  document.title = to.meta.title ? `${to.meta.title} | ${DefaultTitle}` : DefaultTitle
})
