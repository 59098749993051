import { createStore } from 'vuex'
import Auth from '@/store/modules/auth'
import User from '@/store/modules/user'
import Tenant from '@/store/modules/tenant'
import Project from '@/store/modules/project'
import Snackbar from '@/store/modules/snackbar'

const store = createStore({
  modules: {
    Auth,
    User,
    Tenant,
    Project,
    Snackbar
  }
})

export default store
