<template>
  <div class="text-center">
    <v-menu :close-on-content-click="true" :min-width="280">
      <template v-slot:activator="{ props }">
        <v-btn v-bind="props" size="small" class="mr-3" color="white" icon="mdi-account" />
      </template>

      <v-card>
        <v-list>
          <v-list-item>
            <template v-slot:prepend>
              <v-icon>mdi-account</v-icon>
            </template>

            <v-list-item-title>
              {{ authUser.signInUserSession.idToken.payload.email }}
            </v-list-item-title>
          </v-list-item>
        </v-list>

        <v-divider />

        <v-list rounded>
          <!-- <v-list-item to="/password/change">
            <template v-slot:prepend>
              <v-icon />
            </template>
            <v-list-item-title>パスワード変更</v-list-item-title>
          </v-list-item> -->
          <v-list-item @click="logOut()">
            <template v-slot:prepend>
              <v-icon>mdi-logout</v-icon>
            </template>
            <v-list-item-title>ログアウト</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const { mapState: mapStateAuth, mapActions: mapActionsAuth } = createNamespacedHelpers('Auth')

export default {
  name: 'AccountMenu',
  computed: {
    ...mapStateAuth(['authUser'])
  },
  methods: {
    ...mapActionsAuth(['signOut']),
    async logOut() {
      try {
        await this.signOut()
        this.$router.replace('/login')
      } catch (error) {
        console.log('error signing out:', error)
      }
    }
  }
}
</script>
