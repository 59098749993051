import { Auth } from 'aws-amplify'

const AuthStore = {
  namespaced: true,
  state: {
    auth: {
      signedIn: false,
    },
    isAuthorized: false,
    authUser: null,
  },
  mutations: {
    setAuthUser: (state, user) => (state.authUser = user),
    setIsAuthorized: (state, isAuthorized) => (state.isAuthorized = isAuthorized),
    setSignedIn: (state, signedIn) => (state.auth.signedIn = signedIn),
  },
  getters: {
    getAuthUser(state) {
      return state.authUser
    },
    getIsAuthorized(state) {
      return state.isAuthorized
    },
  },
  actions: {
    async signIn({ mailAddress, password }) {
      try {
        return await Auth.signIn(mailAddress, password)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async federatedSignIn(provider) {
      try {
        return await Auth.federatedSignIn({ provider: provider })
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async signOut({ commit }) {
      try {
        await Auth.signOut()
        commit('setAuthUser', {})
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async getUserAuthentication() {
      try {
        return await Auth.currentAuthenticatedUser()
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async setUserAuthentication({ commit }) {
      try {
        const user = await Auth.currentAuthenticatedUser()
        commit('setAuthUser', user)
        commit('setIsAuthorized', true)
      } catch (error) {
        commit('setIsAuthorized', false)
      }
    },
  },
}

export default AuthStore
