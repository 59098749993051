<template>
  <v-snackbar v-model="show" :timeout="snackbarTime" color="rgba(80, 80, 80, 0.8)">
    {{ snackbarText }}

    <template v-slot:actions>
      <v-btn color="blue-lighten-3" variant="text" @click="hide()">閉じる</v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapMutations } = createNamespacedHelpers('Snackbar')

export default {
  name: 'Snackbar',
  computed: {
    ...mapState(['showSnackbar', 'snackbarTime', 'snackbarText']),
    show: {
      set() {
        return this.setShowSnackbar(false)
      },
      get() {
        return this.showSnackbar
      }
    }
  },
  methods: {
    ...mapMutations(['setShowSnackbar', 'setSnackbarText']),
    hide() {
      this.setShowSnackbar(false)
      this.setSnackbarText('')
    }
  }
}
</script>

<style lang="scss"></style>
