<template>
  <div class="customTextarea">
    <v-text-field v-model="textModel" :label="label" class="customTextarea__field" disabled />
    <v-icon
      size="small"
      type="rounded"
      variant="ourlined"
      class="customTextarea__button"
      :icon="!clicked ? 'mdi-clipboard-text' : 'mdi-clipboard-check'"
      @click.prevent="copyToClipboard(value)"
    />
  </div>
</template>

<script>
export default {
  name: 'CopiableTextField',
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    disabled: {
      type: String, // NOTE：HTMLのdisabledはString扱いとなる
      required: false,
      default: 'false'
    }
  },
  data: function () {
    return {
      clicked: false
    }
  },
  computed: {
    textModel: {
      get() {
        return this.value
      },
      set() {
        return this.value
      }
    }
  },
  methods: {
    copyToClipboard(value) {
      navigator.clipboard.writeText(value)
      this.clicked = true
      setTimeout(() => (this.clicked = false), 1000)
    }
  }
}
</script>

<style lang="scss">
.customTextarea {
  position: relative;

  &__field {
    input {
      padding-right: 40px;
    }
  }

  &__button {
    cursor: pointer;
    position: absolute;
    top: 40%;
    right: 12px;
    transform: translateY(-50%);
    z-index: 10;
  }
}
</style>
