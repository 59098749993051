import axios from '@/common/axios'

const User = {
  namespaced: true,
  state: {
    users: [],
    userTokens: [],
    isUnregisteredUser: false,
  },
  mutations: {
    setUser: (state, user) => (state.user = user),
    setUsers: (state, users) => (state.users = users),
    setUserTokens: (state, userTokens) => (state.userTokens = userTokens),
    setUnregisteredUser: (state, isUnregisteredUser) =>
      (state.isUnregisteredUser = isUnregisteredUser),
  },
  getters: {
    user: state => state.user,
  },
  actions: {
    async getUsers({ commit }, { tenantId, role }) {
      try {
        const { data } = await axios.get('/users', {
          params: {
            tenantId: tenantId,
            role: role,
          },
        })
        commit('setUsers', data.users)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async checkUnregisteredUser({ commit }, userId) {
      try {
        const res = await axios.get(`/users/${userId}`)

        commit('setUnregisteredUser', res.status === 404 ? true : false)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async postUsers() {
      try {
        return await axios.post('/users')
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async getUserTokens({ commit }, { tenantId, role }) {
      try {
        const { data } = await axios.get('/users/tokens', {
          params: {
            tenantId: tenantId,
            role: role,
          },
        })

        commit('setUserTokens', data.userTokens)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async postUsersTokens(_, { role, tenantId, projectId, email }) {
      try {
        return await axios.post('/users/tokens', {
          role: role,
          tenantId: tenantId,
          projectId: projectId,
          email: email,
        })
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async putUsersTokens(_, { userToken, tenantId, projectId, role, email }) {
      try {
        return await axios.put(`/users/tokens/${userToken}`, {
          role: role,
          tenantId: tenantId,
          projectId: projectId,
          email: email,
        })
      } catch (error) {
        return Promise.reject(error)
      }
    },
  },
  modules: {},
}

export default User
